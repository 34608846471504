// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #651fad;
    --ion-color-primary-rgb: 101, 31, 173;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #591b98;
    --ion-color-primary-tint: #7435b5;

    /** secondary **/
    --ion-color-secondary: #a172d6;
    --ion-color-secondary-rgb: 161, 114, 214;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #8e64bc;
    --ion-color-secondary-tint: #aa80da;

    /** tertiary **/
    --ion-color-tertiary: #f7f0ff;
    --ion-color-tertiary-rgb: 247, 240, 255;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #d9d3e0;
    --ion-color-tertiary-tint: #f8f2ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #6e6e6e;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-orange: #ef6a37;
    --ion-color-orange-rgb: 239, 106, 55;
    --ion-color-orange-contrast: #ffffff;
    --ion-color-orange-contrast-rgb: 255, 255, 255;
    --ion-color-orange-shade: #d25d30;
    --ion-color-orange-tint: #f1794b;

    --ion-color-mauve: #dcc5ff;
    --ion-color-mauve-rgb: 220, 197, 255;
    --ion-color-mauve-contrast: #ffffff;
    --ion-color-mauve-contrast-rgb: 255, 255, 255;
    --ion-color-mauve-shade: #c2ade0;
    --ion-color-mauve-tint: #e0cbff;

    --ion-color-facebook: #3b5998;
    --ion-color-facebook-rgb: 59, 89, 152;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255, 255, 255;
    --ion-color-facebook-shade: #344e86;
    --ion-color-facebook-tint: #4f6aa2;

    --ion-color-google: #db4437;
    --ion-color-google-rgb: 219, 68, 55;
    --ion-color-google-contrast: #ffffff;
    --ion-color-google-contrast-rgb: 255, 255, 255;
    --ion-color-google-shade: #c13c30;
    --ion-color-google-tint: #df574b;

    --ion-color-lightgray: #f5f5f4;
    --ion-color-lightgray-rgb: 244, 245, 248;
    --ion-color-lightgray-contrast: #000000;
    --ion-color-lightgray-contrast-rgb: 0, 0, 0;
    --ion-color-lightgray-shade: #d7d8da;
    --ion-color-lightgray-tint: #f5f6f9;

    /** searchbar **/
    // --ion-color-searchbar: #f5f5f5;
    // --ion-color-searchbar-rgb: 244, 245, 248;
    // --ion-color-searchbar-contrast: #000000;
    // --ion-color-searchbar-contrast-rgb: 0, 0, 0;
    // --ion-color-searchbar-shade: #d7d8da;
    // --ion-color-searchbar-tint: #f5f6f9;

    /** box shadow **/
    --margaret-box-shadow: 0px 15px 29px rgba(48, 8, 88, 0.2);
}

.ion-color-orange {
    --ion-color-base: var(--ion-color-orange);
    --ion-color-base-rgb: var(--ion-color-orange-rgb);
    --ion-color-contrast: var(--ion-color-orange-contrast);
    --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
    --ion-color-shade: var(--ion-color-orange-shade);
    --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-mauve {
    --ion-color-base: var(--ion-color-mauve);
    --ion-color-base-rgb: var(--ion-color-mauve-rgb);
    --ion-color-contrast: var(--ion-color-mauve-contrast);
    --ion-color-contrast-rgb: var(--ion-color-mauve-contrast-rgb);
    --ion-color-shade: var(--ion-color-mauve-shade);
    --ion-color-tint: var(--ion-color-mauve-tint);
}

.ion-color-facebook {
    --ion-color-base: var(--ion-color-facebook);
    --ion-color-base-rgb: var(--ion-color-facebook-rgb);
    --ion-color-contrast: var(--ion-color-facebook-contrast);
    --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
    --ion-color-shade: var(--ion-color-facebook-shade);
    --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-google {
    --ion-color-base: var(--ion-color-google);
    --ion-color-base-rgb: var(--ion-color-google-rgb);
    --ion-color-contrast: var(--ion-color-google-contrast);
    --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
    --ion-color-shade: var(--ion-color-google-shade);
    --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-lightgray {
    --ion-color-base: var(--ion-color-lightgray);
    --ion-color-base-rgb: var(--ion-color-lightgray-rgb);
    --ion-color-contrast: var(--ion-color-lightgray-contrast);
    --ion-color-contrast-rgb: var(--ion-color-lightgray-contrast-rgb);
    --ion-color-shade: var(--ion-color-lightgray-shade);
    --ion-color-tint: var(--ion-color-lightgray-tint);
}

// @media (prefers-color-scheme: dark) {
//     /*
//    * Dark Colors
//    * -------------------------------------------
//    */
//     :root {
//         body {
//             /** primary **/
//             --ion-color-primary: #651fad;
//             --ion-color-primary-rgb: 101, 31, 173;
//             --ion-color-primary-contrast: #ffffff;
//             --ion-color-primary-contrast-rgb: 255, 255, 255;
//             --ion-color-primary-shade: #591b98;
//             --ion-color-primary-tint: #7435b5;

//             /** secondary **/
//             --ion-color-secondary: #a172d6;
//             --ion-color-secondary-rgb: 161, 114, 214;
//             --ion-color-secondary-contrast: #ffffff;
//             --ion-color-secondary-contrast-rgb: 255, 255, 255;
//             --ion-color-secondary-shade: #8e64bc;
//             --ion-color-secondary-tint: #aa80da;

//             /** tertiary **/
//             --ion-color-tertiary: #f7f0ff;
//             --ion-color-tertiary-rgb: 247, 240, 255;
//             --ion-color-tertiary-contrast: #651fad;
//             --ion-color-tertiary-contrast-rgb: 0, 0, 0;
//             --ion-color-tertiary-shade: #d9d3e0;
//             --ion-color-tertiary-tint: #f8f2ff;

//             /** success **/
//             --ion-color-success: #2dd36f;
//             --ion-color-success-rgb: 45, 211, 111;
//             --ion-color-success-contrast: #ffffff;
//             --ion-color-success-contrast-rgb: 255, 255, 255;
//             --ion-color-success-shade: #28ba62;
//             --ion-color-success-tint: #42d77d;

//             /** warning **/
//             --ion-color-warning: #ffc409;
//             --ion-color-warning-rgb: 255, 196, 9;
//             --ion-color-warning-contrast: #000000;
//             --ion-color-warning-contrast-rgb: 0, 0, 0;
//             --ion-color-warning-shade: #e0ac08;
//             --ion-color-warning-tint: #ffca22;

//             /** danger **/
//             --ion-color-danger: #eb445a;
//             --ion-color-danger-rgb: 235, 68, 90;
//             --ion-color-danger-contrast: #ffffff;
//             --ion-color-danger-contrast-rgb: 255, 255, 255;
//             --ion-color-danger-shade: #cf3c4f;
//             --ion-color-danger-tint: #ed576b;

//             --ion-color-dark: #f4f5f8;
//             --ion-color-dark-rgb: 244, 245, 248;
//             --ion-color-dark-contrast: #000000;
//             --ion-color-dark-contrast-rgb: 0, 0, 0;
//             --ion-color-dark-shade: #d7d8da;
//             --ion-color-dark-tint: #f5f6f9;

//             --ion-color-medium: #989aa2;
//             --ion-color-medium-rgb: 152, 154, 162;
//             --ion-color-medium-contrast: #000000;
//             --ion-color-medium-contrast-rgb: 0, 0, 0;
//             --ion-color-medium-shade: #86888f;
//             --ion-color-medium-tint: #a2a4ab;

//             --ion-color-light: #222428;
//             --ion-color-light-rgb: 34, 36, 40;
//             --ion-color-light-contrast: #ffffff;
//             --ion-color-light-contrast-rgb: 255, 255, 255;
//             --ion-color-light-shade: #1e2023;
//             --ion-color-light-tint: #383a3e;

//             --ion-color-orange: #ef6a37;
//             --ion-color-orange-rgb: 239, 106, 55;
//             --ion-color-orange-contrast: #ffffff;
//             --ion-color-orange-contrast-rgb: 255, 255, 255;
//             --ion-color-orange-shade: #d25d30;
//             --ion-color-orange-tint: #f1794b;

//             --ion-color-mauve: #dcc5ff;
//             --ion-color-mauve-rgb: 220, 197, 255;
//             --ion-color-mauve-contrast: #ffffff;
//             --ion-color-mauve-contrast-rgb: 255, 255, 255;
//             --ion-color-mauve-shade: #c2ade0;
//             --ion-color-mauve-tint: #e0cbff;

//             --ion-color-facebook: #3b5998;
//             --ion-color-facebook-rgb: 59, 89, 152;
//             --ion-color-facebook-contrast: #ffffff;
//             --ion-color-facebook-contrast-rgb: 255, 255, 255;
//             --ion-color-facebook-shade: #344e86;
//             --ion-color-facebook-tint: #4f6aa2;

//             /** searchbar **/
//             // --ion-color-searchbar: #f7f0ff;
//             // --ion-color-searchbar-rgb: 247, 240, 255;
//             // --ion-color-searchbar-contrast: #000000;
//             // --ion-color-searchbar-contrast-rgb: 0, 0, 0;
//             // --ion-color-searchbar-shade: #d9d3e0;
//             // --ion-color-searchbar-tint: #f8f2ff;

//             /** box shadow **/
//             --margaret-box-shadow: 0px 15px 29px rgba(48, 8, 88, 0.2);
//         }

//         /*
//        * iOS Dark Theme
//        * -------------------------------------------
//        */

//         .ios body {
//             --ion-background-color: var(--ion-color-step-100);
//             --ion-background-color-rgb: 42, 42, 42;

//             --ion-text-color: #ffffff;
//             --ion-text-color-rgb: 255, 255, 255;

//             --ion-color-step-50: #0d0d0d;
//             --ion-color-step-100: #1a1a1a;
//             --ion-color-step-150: #262626;
//             --ion-color-step-200: #333333;
//             --ion-color-step-250: #404040;
//             --ion-color-step-300: #4d4d4d;
//             --ion-color-step-350: #595959;
//             --ion-color-step-400: #666666;
//             --ion-color-step-450: #737373;
//             --ion-color-step-500: #808080;
//             --ion-color-step-550: #8c8c8c;
//             --ion-color-step-600: #999999;
//             --ion-color-step-650: #a6a6a6;
//             --ion-color-step-700: #b3b3b3;
//             --ion-color-step-750: #bfbfbf;
//             --ion-color-step-800: #cccccc;
//             --ion-color-step-850: #d9d9d9;
//             --ion-color-step-900: #e6e6e6;
//             --ion-color-step-950: #f2f2f2;

//             --ion-item-background: var(--ion-color-step-150);

//             --ion-toolbar-background: var(--ion-color-step-150);

//             --ion-tab-bar-background: var(--ion-color-step-150);

//             --ion-card-background: var(--ion-color-step-150);
//         }

//         .ios ion-modal {
//             --ion-background-color: var(--ion-color-step-100);
//             --ion-toolbar-background: var(--ion-color-step-150);
//             --ion-toolbar-border-color: var(--ion-color-step-250);
//         }

//         /*
//        * Material Design Dark Theme
//        * -------------------------------------------
//        */

//         .md body {
//             --ion-background-color: var(--ion-color-step-100);
//             --ion-background-color-rgb: 42, 42, 42;

//             --ion-text-color: #ffffff;
//             --ion-text-color-rgb: 255, 255, 255;

//             --ion-border-color: #222222;

//             --ion-color-step-50: #1e1e1e;
//             --ion-color-step-100: #2a2a2a;
//             --ion-color-step-150: #363636;
//             --ion-color-step-200: #414141;
//             --ion-color-step-250: #4d4d4d;
//             --ion-color-step-300: #595959;
//             --ion-color-step-350: #656565;
//             --ion-color-step-400: #717171;
//             --ion-color-step-450: #7d7d7d;
//             --ion-color-step-500: #898989;
//             --ion-color-step-550: #949494;
//             --ion-color-step-600: #a0a0a0;
//             --ion-color-step-650: #acacac;
//             --ion-color-step-700: #b8b8b8;
//             --ion-color-step-750: #c4c4c4;
//             --ion-color-step-800: #d0d0d0;
//             --ion-color-step-850: #dbdbdb;
//             --ion-color-step-900: #e7e7e7;
//             --ion-color-step-950: #f3f3f3;

//             --ion-item-background: var(--ion-color-step-150);

//             --ion-toolbar-background: var(--ion-color-step-150);

//             --ion-tab-bar-background: var(--ion-color-step-150);

//             --ion-card-background: var(--ion-color-step-150);
//         }
//     }
// }

// body.dark {
//     body {
//         /** primary **/
//         --ion-color-primary: #651fad;
//         --ion-color-primary-rgb: 101, 31, 173;
//         --ion-color-primary-contrast: #ffffff;
//         --ion-color-primary-contrast-rgb: 255, 255, 255;
//         --ion-color-primary-shade: #591b98;
//         --ion-color-primary-tint: #7435b5;

//         /** secondary **/
//         --ion-color-secondary: #a172d6;
//         --ion-color-secondary-rgb: 161, 114, 214;
//         --ion-color-secondary-contrast: #ffffff;
//         --ion-color-secondary-contrast-rgb: 255, 255, 255;
//         --ion-color-secondary-shade: #8e64bc;
//         --ion-color-secondary-tint: #aa80da;

//         /** tertiary **/
//         --ion-color-tertiary: #f7f0ff;
//         --ion-color-tertiary-rgb: 247, 240, 255;
//         --ion-color-tertiary-contrast: #651fad;
//         --ion-color-tertiary-contrast-rgb: 0, 0, 0;
//         --ion-color-tertiary-shade: #d9d3e0;
//         --ion-color-tertiary-tint: #f8f2ff;

//         /** success **/
//         --ion-color-success: #2dd36f;
//         --ion-color-success-rgb: 45, 211, 111;
//         --ion-color-success-contrast: #ffffff;
//         --ion-color-success-contrast-rgb: 255, 255, 255;
//         --ion-color-success-shade: #28ba62;
//         --ion-color-success-tint: #42d77d;

//         /** warning **/
//         --ion-color-warning: #ffc409;
//         --ion-color-warning-rgb: 255, 196, 9;
//         --ion-color-warning-contrast: #000000;
//         --ion-color-warning-contrast-rgb: 0, 0, 0;
//         --ion-color-warning-shade: #e0ac08;
//         --ion-color-warning-tint: #ffca22;

//         /** danger **/
//         --ion-color-danger: #eb445a;
//         --ion-color-danger-rgb: 235, 68, 90;
//         --ion-color-danger-contrast: #ffffff;
//         --ion-color-danger-contrast-rgb: 255, 255, 255;
//         --ion-color-danger-shade: #cf3c4f;
//         --ion-color-danger-tint: #ed576b;

//         --ion-color-dark: #f4f5f8;
//         --ion-color-dark-rgb: 244, 245, 248;
//         --ion-color-dark-contrast: #000000;
//         --ion-color-dark-contrast-rgb: 0, 0, 0;
//         --ion-color-dark-shade: #d7d8da;
//         --ion-color-dark-tint: #f5f6f9;

//         --ion-color-medium: #989aa2;
//         --ion-color-medium-rgb: 152, 154, 162;
//         --ion-color-medium-contrast: #000000;
//         --ion-color-medium-contrast-rgb: 0, 0, 0;
//         --ion-color-medium-shade: #86888f;
//         --ion-color-medium-tint: #a2a4ab;

//         --ion-color-light: #222428;
//         --ion-color-light-rgb: 34, 36, 40;
//         --ion-color-light-contrast: #ffffff;
//         --ion-color-light-contrast-rgb: 255, 255, 255;
//         --ion-color-light-shade: #1e2023;
//         --ion-color-light-tint: #383a3e;

//         --ion-color-orange: #ef6a37;
//         --ion-color-orange-rgb: 239, 106, 55;
//         --ion-color-orange-contrast: #ffffff;
//         --ion-color-orange-contrast-rgb: 255, 255, 255;
//         --ion-color-orange-shade: #d25d30;
//         --ion-color-orange-tint: #f1794b;

//         --ion-color-mauve: #dcc5ff;
//         --ion-color-mauve-rgb: 220, 197, 255;
//         --ion-color-mauve-contrast: #ffffff;
//         --ion-color-mauve-contrast-rgb: 255, 255, 255;
//         --ion-color-mauve-shade: #c2ade0;
//         --ion-color-mauve-tint: #e0cbff;

//         --ion-color-facebook: #3b5998;
//         --ion-color-facebook-rgb: 59, 89, 152;
//         --ion-color-facebook-contrast: #ffffff;
//         --ion-color-facebook-contrast-rgb: 255, 255, 255;
//         --ion-color-facebook-shade: #344e86;
//         --ion-color-facebook-tint: #4f6aa2;

//         /** searchbar **/
//         // --ion-color-searchbar: #f7f0ff;
//         // --ion-color-searchbar-rgb: 247, 240, 255;
//         // --ion-color-searchbar-contrast: #000000;
//         // --ion-color-searchbar-contrast-rgb: 0, 0, 0;
//         // --ion-color-searchbar-shade: #d9d3e0;
//         // --ion-color-searchbar-tint: #f8f2ff;

//         /** box shadow **/
//         --margaret-box-shadow: 0px 15px 29px rgba(48, 8, 88, 0.2);
//     }

//     /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//     .ios body {
//         --ion-background-color: var(--ion-color-step-100);
//         --ion-background-color-rgb: 42, 42, 42;

//         --ion-text-color: #ffffff;
//         --ion-text-color-rgb: 255, 255, 255;

//         --ion-color-step-50: #0d0d0d;
//         --ion-color-step-100: #1a1a1a;
//         --ion-color-step-150: #262626;
//         --ion-color-step-200: #333333;
//         --ion-color-step-250: #404040;
//         --ion-color-step-300: #4d4d4d;
//         --ion-color-step-350: #595959;
//         --ion-color-step-400: #666666;
//         --ion-color-step-450: #737373;
//         --ion-color-step-500: #808080;
//         --ion-color-step-550: #8c8c8c;
//         --ion-color-step-600: #999999;
//         --ion-color-step-650: #a6a6a6;
//         --ion-color-step-700: #b3b3b3;
//         --ion-color-step-750: #bfbfbf;
//         --ion-color-step-800: #cccccc;
//         --ion-color-step-850: #d9d9d9;
//         --ion-color-step-900: #e6e6e6;
//         --ion-color-step-950: #f2f2f2;

//         --ion-item-background: var(--ion-color-step-150);

//         --ion-toolbar-background: var(--ion-color-step-150);

//         --ion-tab-bar-background: var(--ion-color-step-150);

//         --ion-card-background: var(--ion-color-step-150);
//     }

//     .ios ion-modal {
//         --ion-background-color: var(--ion-color-step-100);
//         --ion-toolbar-background: var(--ion-color-step-150);
//         --ion-toolbar-border-color: var(--ion-color-step-250);
//     }

//     /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//     .md body {
//         --ion-background-color: var(--ion-color-step-100);
//         --ion-background-color-rgb: 42, 42, 42;

//         --ion-text-color: #ffffff;
//         --ion-text-color-rgb: 255, 255, 255;

//         --ion-border-color: #222222;

//         --ion-color-step-50: #1e1e1e;
//         --ion-color-step-100: #2a2a2a;
//         --ion-color-step-150: #363636;
//         --ion-color-step-200: #414141;
//         --ion-color-step-250: #4d4d4d;
//         --ion-color-step-300: #595959;
//         --ion-color-step-350: #656565;
//         --ion-color-step-400: #717171;
//         --ion-color-step-450: #7d7d7d;
//         --ion-color-step-500: #898989;
//         --ion-color-step-550: #949494;
//         --ion-color-step-600: #a0a0a0;
//         --ion-color-step-650: #acacac;
//         --ion-color-step-700: #b8b8b8;
//         --ion-color-step-750: #c4c4c4;
//         --ion-color-step-800: #d0d0d0;
//         --ion-color-step-850: #dbdbdb;
//         --ion-color-step-900: #e7e7e7;
//         --ion-color-step-950: #f3f3f3;

//         --ion-item-background: var(--ion-color-step-150);

//         --ion-toolbar-background: var(--ion-color-step-150);

//         --ion-tab-bar-background: var(--ion-color-step-150);

//         --ion-card-background: var(--ion-color-step-150);
//     }
// }
